export const LogoGridBlueBackground = () => {
  return (
    <div className="py-16 bg-primary-700 lg:py-24">
      <div className="px-5 max-w-(--breakpoint-xl) mx-auto">
        <p className="font-display text-base text-ui-50 text-center mb-8">
          Trusted by Agencies and Users Worldwide
        </p>
        <ul
          role="list"
          className="grid grid-cols-3 gap-6 md:grid-cols-6 max-w-[34rem] md:mx-auto"
        >
          <li className="inline-flex items-center justify-center">
            <img
              className="w-full"
              alt="AppSumo Logo"
              src="/images/home/appsumo-logo.png"
            />
          </li>
          <li className="inline-flex items-center justify-center">
            <img
              className="w-[80px] md:w-[60px]"
              alt="Github Logo"
              src="/images/home/github-logo.png"
            />
          </li>
          <li className="inline-flex items-center justify-center">
            <img alt="Nextdoor Logo" src="/images/home/nextdoor-logo.png" />
          </li>
          <li className="inline-flex items-center justify-center">
            <img alt="OVB Media Logo" src="/images/home/ovb-logo.png" />
          </li>
          <li className="inline-flex items-center justify-center">
            <img
              className="w-[80px]  md:w-[60px]"
              alt="Zapier Logo"
              src="/images/home/zapier-logo.png"
            />
          </li>
          <li className="inline-flex items-center justify-center">
            <img
              alt="Zebra Associates Logo"
              src="/images/home/zebra-logo.png"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
