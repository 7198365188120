import { CheckIcon } from '@heroicons/react/20/solid';
import {
  ROUTES,
  getAddonListingTrackedRedirect,
  handleClickInstallAddon,
} from '../../lib/links';
import { ButtonNew } from '../ui/ButtonNew';

const CARD_DATA = [
  {
    name: 'Google Sheets',
    icon: '/images/home/google-sheets.png',
    features: [
      'Data-driven and flexible interface',
      'Apply functions and formulas, sort and filter',
      'Custom charts, graphs and formatting',
    ],

    learnMoreHref: ROUTES.googleSheets,
  },
  {
    name: 'Looker Studio',
    icon: '/images/home/looker-studio-logo.png',
    features: [
      'Build visual reports and interactive dashboards ',
      'Ideal for advanced data modelling ',
      'Share real time visual dashboards with clients',
    ],
    learnMoreHref: ROUTES.lookerStudio,
  },
] as const;

function Cta() {
  return (
    <section className="text-primary-950 container-default lg:pb-24">
      <div className="text-center">
        <h5 className="text-[2.5rem] font-heading mb-6 leading-[120%]">
          Save Time, Start Syncing!
        </h5>
        <p className="text-xl max-w-[48rem] mx-auto">
          Start with 35 refreshes per month at no charge on our Free plan.
          SyncWith’s pricing scales with your needs, up to unlimited refreshes
          from any service and reports that never expire.
        </p>
      </div>
      <div className="text-center mb-8 md:mb-12">
        <h5 className="text-3xl font-heading mb-0">
          Plans Starting at $24.99/month
        </h5>
        <p className="text-sm mt-4">No credit card required.</p>
      </div>
      <div className="space-y-6 md:flex md:space-y-0 gap-8 justify-center">
        {CARD_DATA.map(({ features, icon, learnMoreHref, name }) => (
          <div key={name} className="bg-ui-100 p-8 rounded-default">
            <div className="flex items-center gap-3">
              <img className="w-6" src={icon} alt="Icon Google Sheets" />
              <div className="font-heading text-3xl">{name}</div>
            </div>
            <ul className="mt-4 mb-6 space-y-2">
              {features.map((feature) => (
                <li className="flex items-center gap-2" key={feature}>
                  <CheckIcon className="w-6 shrink-0" />
                  {feature}
                </li>
              ))}
            </ul>
            <div className="flex flex-col w-full gap-2">
              {name === 'Google Sheets' ? (
                <ButtonNew asChild variant={'defaultWhiteBg'} size={'lg'}>
                  <a
                    onClick={(e) => handleClickInstallAddon(e, 'sitenav')}
                    href={getAddonListingTrackedRedirect('sitenav')}
                    target="_blank"
                    rel="noopener nofollow"
                  >
                    Try it for {name}
                  </a>
                </ButtonNew>
              ) : (
                <ButtonNew asChild variant={'defaultWhiteBg'} size={'lg'}>
                  <a href={ROUTES.lookerStudioIntegrations}>
                    Get Started with {name}
                  </a>
                </ButtonNew>
              )}
              <ButtonNew asChild size={'lg'} variant={'underline'}>
                <a href={learnMoreHref}>More about {name}</a>
              </ButtonNew>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Cta;
