function BenefitsSection() {
  return (
    <section className="container-default px-5 py-16 lg:py-24">
      <h3 className="text-4xl font-heading font-normal text-center">
        Simple and Superpowered
      </h3>
      <div className="mt-8 md:mt-12 space-y-6 md:space-y-0 md:flex justify-between gap-8">
        <div>
          <div className="text-primary-950 font-semibold text-lg mb-2">
            Use Familiar Tools
          </div>
          <p className="text-base	text-primary-950">
            Merge and combine your channels into Google Sheets or Looker
            Studio--tools you already know.
          </p>
        </div>
        <div>
          <div className="text-primary-950 font-semibold text-lg mb-2">
            Harness Live Data
          </div>
          <p className="text-base	text-primary-950">
            Keep your finger on the pulse, monitor spend and performance, and
            share live reports any time.
          </p>
        </div>
        <div>
          <div className="text-primary-950 font-semibold text-lg mb-2">
            Get Focused
          </div>
          <p className="text-base	text-primary-950">
            Cut through the noise and focus only on the metrics that matter most
            for your unique goals.
          </p>
        </div>
        <div>
          <div className="text-primary-950 font-semibold text-lg mb-2">
            Create Expert Reports{' '}
          </div>
          <p className="text-base	text-primary-950">
            SyncWith is quick and simple to configure, no coding or analytics
            expertise required!
          </p>
        </div>
      </div>
    </section>
  );
}

export default BenefitsSection;
