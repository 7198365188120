import { ROUTES } from '../../lib/links';

interface Props {
  iconHref: string;
  title: string;
  alt: string;
  pageLink: (typeof ROUTES)[keyof typeof ROUTES];
  text: React.ReactNode | string;
}

function IntegrationCard(props: Props) {
  const { iconHref, pageLink, text, title, alt } = props;

  return (
    <a
      href={pageLink}
      className="p-8 bg-white block rounded-2xl  hover:shadow-integration-card-hover shadow-integration-card transition-all"
    >
      <div className="flex items-center gap-2 mb-2">
        <div>
          <img className="w-6" src={iconHref} alt={alt} />
        </div>
        <div>
          <div className="font-semibold text-ui-950 text-xl">{title}</div>
        </div>
      </div>
      <div className="text-ui-950">{text}</div>
    </a>
  );
}

export default IntegrationCard;
