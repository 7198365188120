function IntegrationSection() {
  return (
    <section className="bg-primary-700 pb-16 lg:pb-24 ">
      <div className="container-default md:flex gap-12 ">
        <img
          className="mb-8 w-full h-full md:max-w-[18.875rem]"
          alt="Google sheets graphic"
          src="/images/home/graphic-google-sheets.webp"
        />
        <div className="">
          <h2 className="font-heading leading-[1.1] text-ui-50 mb-8 text-[2.625rem] md:text-5xl font-normal">
            SyncWith integrates your marketing channels with{' '}
            <br className="hidden md:block" /> Google Sheets & Looker Studio
          </h2>
          <p className="text-ui-50 text-lg">
            Centralize your tracked marketing metrics using SyncWith for Google
            Sheets, or multiple source connectors for Looker Studio. SyncWith
            updates automatically, so you can share consistently fresh custom
            reports.
          </p>
        </div>
      </div>
    </section>
  );
}

export default IntegrationSection;
