import { ROUTES } from '../../lib/links';
import { ButtonNew } from '../ui/ButtonNew';
import IntegrationCard from './IntegrationCard';

function GoogleIntegrations() {
  return (
    <section className="container-default pb-16 lg:py-24 ">
      <div className="space-y-6 lg:space-y-0 text-center flex items-center flex-col justify-center lg:flex-row">
        <img
          className="max-w-[2.938rem] w-full mr-4"
          src={'/images/home/google-sheets.png'}
          alt="Google Sheets logo"
        />
        <h3 className="mb-6  mt-0 text-[2.5rem] font-normal font-heading">
          SyncWith for <br className="lg:hidden" />
          <span className="text-secondary-500">Google Sheets</span>
        </h3>
      </div>
      <p className="text-ui-950 text-center mt-6 max-w-[49rem] mx-auto lg:mb-16">
        Use <b>SyncWith for Google Sheets</b> to bring the latest metrics from
        your favorite <br className="hidden lg:block" /> marketing channels into
        Google Sheets.
      </p>
      <div className="mt-6 space-y-4 lg:space-y-0 lg:grid grid-rows-2 grid-cols-2 gap-8 ">
        <IntegrationCard
          title="Facebook Ads"
          text="Monitor campaign spend, performance, clicks, conversions, and more. Break down data by day, week, or month."
          iconHref="/images/home/facebook-ads.png"
          alt="Facebook Ads logo"
          pageLink={ROUTES.googleSheetsFacebookAds}
        />
        <IntegrationCard
          title="Google Ads"
          alt="Google Ads logo"
          iconHref="images/home/google-ads.png"
          text="Analyze spend, track campaigns, ad groups, keywords, search terms, conversions, and more."
          pageLink={ROUTES.googleSheetsGoogleAds}
        />
        <IntegrationCard
          title="Google Analytics"
          text="Directly extract raw data from Google Analytics v4, create custom reports, segment data, and track by day, week, or month."
          iconHref="/images/home/ga4-google-analytics.png"
          alt="Google Analytics"
          pageLink={ROUTES.googleSheetsGoogleAnalytics}
        />
        <IntegrationCard
          alt="Shopify logo"
          title="Shopify"
          text="Integrate your Shopify store to import Orders and Products into Google Sheets. Utilize pivot tables to report sales by day, week, or month, and beyond."
          iconHref="/images/home/shopify.png"
          pageLink={ROUTES.googleSheetsShopify}
        />
      </div>
      <div className="flex justify-center">
        <ButtonNew
          variant={'defaultWhiteBg'}
          size={'lg'}
          className="w-full lg:w-auto mt-12 lg:mt-16"
          asChild
        >
          <a href={ROUTES.googleSheetsIntegrations}>
            See all Google Sheets Connectors
          </a>
        </ButtonNew>
      </div>
    </section>
  );
}

export default GoogleIntegrations;
