import {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from '@remix-run/node';
import { metaV1 } from '@remix-run/v1-meta';
import { Styles } from '../components/Styles';
import { loader as commonCachingHeadersLoader } from '../components/caching/common.server';
import BenefitsSection from '../components/home/BenefitsSection';
import GoogleIntegrations from '../components/home/GoogleIntegrations';
import { Hero } from '../components/home/Hero';
import IntegrationSection from '../components/home/IntegrationSection';
import { LogoGridBlueBackground } from '../components/home/LogoGridBlueBackground';
import LookerStudioIntegrations from '../components/home/LookerStudioIntegrations';
import TestimonialsSlider from '../components/home/TestimonialsSlider';
import TestimonialsSmall from '../components/home/TestimonialsSmall';
import Cta from '../components/home/cta';
import { SiteNav } from '../components/site/Navigation/SiteNav';
import { SiteFooter } from '../components/site/SiteFooter';
import { SW_HOST, getAppName, getProductionHostname } from '../lib/env';
// import { BreakpointHelper } from '../components/BreakpointHelper';

export const loader = async (args: LoaderFunctionArgs) => {
  const { request } = args;
  const url = new URL(request.url);
  if (url.searchParams.get('category')) {
    throw redirect(`https://${getProductionHostname()}`, 301);
  }

  return commonCachingHeadersLoader(args);
};

export const links: LinksFunction = () => {
  return [
    ...Styles.app,
    {
      rel: 'canonical',
      href: `https://${SW_HOST}`,
    },
  ];
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return loaderHeaders;
};

export const meta: MetaFunction = (args) => {
  return metaV1(args, {
    title: `${getAppName()} | Get your data into Google Sheets`,
    description: `${getAppName()} is the easiest way to import your data into Google Sheets from 3rd party apps, services, APIs, databases and more.`,
  });
};

export default function Index() {
  return (
    <>
      <div className="flex flex-col h-screen">
        <SiteNav />
        <Hero />
        <LogoGridBlueBackground />
        <IntegrationSection />
        <BenefitsSection />
        {/*         To be added once we have the marketer content.  */}
        {/*         <MarketerTabs /> */}
        <GoogleIntegrations />
        <LookerStudioIntegrations />
        <TestimonialsSlider />
        <TestimonialsSmall />
        <Cta />

        <div className={'mt-10 md:mt-20'}>
          <SiteFooter />
        </div>
      </div>
    </>
  );
}
