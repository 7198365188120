import { ROUTES } from '../../lib/links';
import { ButtonNew } from '../ui/ButtonNew';
import IntegrationCard from './IntegrationCard';

function LookerStudioIntegrations() {
  return (
    <section className="container-default pb-16 lg:pb-24 ">
      <div className="space-y-6 lg:space-y-0 text-center flex items-center flex-col justify-center lg:flex-row">
        <img
          className="max-w-[2.938rem] w-full mr-4"
          src={'/images/home/looker-studio-logo.png'}
          alt="Google Sheets logo"
        />
        <h3 className="mb-6  mt-0 text-[2.5rem] font-normal font-heading">
          SyncWith for <br className="lg:hidden" />
          <span className="text-secondary-500">Looker Studio</span>
        </h3>
      </div>
      <p className="text-ui-950 text-center mt-6 max-w-[49rem] mx-auto lg:mb-16">
        Automate beautiful reports and data visualizations using{' '}
        <b>SyncWith’s connectors for Looker Studio.</b> Each connector brings
        data from one source into Looker Studio (formerly Google Data Studio).
      </p>
      <div className="mt-6 space-y-6 lg:space-y-0 lg:grid grid-rows-2 grid-cols-2 gap-8 ">
        <IntegrationCard
          title="Facebook Ads"
          text="Track key metrics like ad spend, impressions, clicks, conversions and more. Create tailored reports with custom aggregations and identify trends. "
          iconHref="/images/home/facebook-ads.png"
          alt="Facebook Ads logo"
          pageLink={ROUTES.lookerStudioGoogleDataStudioFacebookAds}
        />
        <IntegrationCard
          alt="Shopify logo"
          title="Shopify"
          text="Connect your Shopify store to pull Orders and Products into Looker Studio, use pivot tables to report on sales by day, week or month and more."
          iconHref="/images/home/shopify.png"
          pageLink={ROUTES.lookerStudioShopify}
        />
        <IntegrationCard
          title="Stripe"
          text="Sync Stripe charges and sales data into Looker Studio for financial insights and attractive reports. "
          iconHref="/images/home/stripe.png"
          alt="Stripe Logo"
          pageLink={ROUTES.lookerStudioStripe}
        />
      </div>
      <div className="flex justify-center">
        <ButtonNew
          variant={'defaultWhiteBg'}
          size={'lg'}
          className="w-full lg:w-auto mt-12 lg:mt-16"
          asChild
        >
          <a href={ROUTES.lookerStudioIntegrations}>
            See all Looker Studio Connectors
          </a>
        </ButtonNew>
      </div>
    </section>
  );
}

export default LookerStudioIntegrations;
