import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Select from '@radix-ui/react-select';
import { useState } from 'react';
import {
  ROUTES,
  getAddonListingTrackedRedirect,
  handleClickInstallAddon,
} from '../../lib/links';
import { ButtonNew } from '../ui/ButtonNew';

const DROPDOWN_VALUES = {
  GOOGLE_SHEETS: 'Google Sheets',
  LOOKER_STUDIO: 'Looker Studio',
  UNSELECTED: '',
} as const;

type DropdownUnion = (typeof DROPDOWN_VALUES)[keyof typeof DROPDOWN_VALUES];

export const Hero = () => {
  const [selectedDataSource, setSelectedDataSource] =
    useState<DropdownUnion>('');
  const [error, setError] = useState('');

  function renderButton() {
    const buttonText = 'Get started';

    if (selectedDataSource === DROPDOWN_VALUES.GOOGLE_SHEETS) {
      return (
        <ButtonNew
          variant={'default'}
          className="w-full md:w-auto"
          size={'lg'}
          asChild
        >
          <a
            onClick={(e) => handleClickInstallAddon(e, 'sitenav')}
            href={getAddonListingTrackedRedirect('sitenav')}
            target="_blank"
            rel="noopener nofollow"
          >
            {buttonText}
          </a>
        </ButtonNew>
      );
    }

    if (selectedDataSource === DROPDOWN_VALUES.LOOKER_STUDIO) {
      return (
        <ButtonNew
          variant={'default'}
          className="w-full md:w-auto"
          size={'lg'}
          asChild
        >
          <a href={ROUTES.lookerStudioIntegrations}>{buttonText}</a>
        </ButtonNew>
      );
    }

    return (
      <ButtonNew
        className="w-full md:w-auto"
        onClick={() => {
          setError('Select a data destination from the dropdown');
        }}
        size={'lg'}
      >
        {buttonText}
      </ButtonNew>
    );
  }

  return (
    <div className="lg:pt-12 bg-primary-800">
      <main>
        <div className="text-center container-default  pb-9">
          <h1 className=" text-[2.5rem] font-semibold font-heading text-white tracking-tight sm:text-6xl">
            Bring all your <br />
            <span className="text-secondary-500">marketing reports</span>{' '}
            together
          </h1>
          <p className="mt-8 text-ui-50">
            Create automatic, up-to-date marketing reports in Google Sheets or{' '}
            <br className="md:block hidden" /> Looker Studio from Facebook Ads,
            Google Analytics, Shopify & more.
          </p>
          <p className="text-ui-50 mt-3">
            SyncWith is <b>free</b> to try--set up in minutes!
          </p>
          <div className="mt-10 flex items-center flex-col md:flex-row justify-center gap-4 max-w-md mx-auto lg:max-w-none lg:mx-0">
            <Select.Root
              onValueChange={(value) => {
                setError('');
                const castvalue = value as DropdownUnion;
                setSelectedDataSource(castvalue);
              }}
              value={selectedDataSource}
            >
              <Select.Trigger
                className="bg-white w-full lg:w-[17.8rem] justify-between md:justify-left md:w-auto text-center md:text-left h-12 text-sm  px-8 inline-flex items-center gap-2 text-primary-950 rounded-full"
                aria-label="Data Destination"
              >
                <Select.Value
                  aria-label={selectedDataSource}
                  placeholder="Choose Your Data Destination"
                >
                  {selectedDataSource}
                </Select.Value>
                <Select.Icon className="SelectIcon">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.53478 7.09139C6.36256 7.31162 5.93251 7.31162 5.76028 7.09139L1.01876 1.0284C0.846359 0.807953 1.06155 0.532226 1.40601 0.532226L10.8891 0.532227C11.2335 0.532227 11.4487 0.807953 11.2763 1.0284L6.53478 7.09139Z"
                      fill="#080121"
                    />
                  </svg>
                </Select.Icon>
              </Select.Trigger>
              <Select.Content
                ref={(ref) => {
                  if (!ref) return;
                  // There is a library bug where the button below the dropdown gets triggered.
                  // This is not an official soluton but rather a workaround.
                  // https://github.com/radix-ui/primitives/issues/1658#issuecomment-2108955928
                  // eslint-disable-next-line
                  ref.ontouchstart = (e) => e.preventDefault();
                }}
                className="data-[state=open]:animate-fade-in-down data-[state=closed]:animate-fade-out-up"
                align="end"
                position="popper"
              >
                <Select.Viewport className="bg-white  dark:bg-gray-800 p-2 rounded-default shadow-lg w-(--radix-select-trigger-width) mt-2">
                  <Select.Item
                    value={DROPDOWN_VALUES.GOOGLE_SHEETS}
                    className="relative block text-left hover:bg-ui-100 focus:outline-hidden cursor-pointer px-6 py-2 rounded-lg text-sm text-gray-700 dark:text-gray-300 font-medium focus:bg-gray-100 dark:focus:bg-gray-900"
                  >
                    Google Sheets
                  </Select.Item>
                  <Select.Item
                    value={DROPDOWN_VALUES.LOOKER_STUDIO}
                    className="relative block text-left  hover:bg-ui-100 focus:outline-hidden cursor-pointer px-6 py-2  rounded-lg text-sm text-gray-700 dark:text-gray-300 font-medium focus:bg-gray-100 dark:focus:bg-gray-900"
                  >
                    Looker Studio
                  </Select.Item>
                </Select.Viewport>
                <Select.ScrollDownButton className="SelectScrollButton">
                  <ChevronDownIcon />
                </Select.ScrollDownButton>
              </Select.Content>
            </Select.Root>

            {renderButton()}
          </div>
          {error ? (
            <div className="text-sm transition-all opacity-100 animate-fade-in-down font-medium text-secondary-500 mt-4">
              {error}
            </div>
          ) : (
            <p className="text-sm text-ui-50 mt-4">
              Get your first 35 reports for free, no credit card required.
            </p>
          )}
        </div>
        <div
          style={{
            background: `linear-gradient(0deg, #1A2487 25.00%, #000C7A 25.00%)`,
          }}
          className="flex justify-center "
        >
          <div className="container-default ">
            <img
              width={980}
              height={540}
              className="w-full"
              sizes="(max-width: 980px) 100vw, 980px"
              src="/images/home/hero-graphic.webp"
              alt="Graphic of tool"
            />
          </div>
        </div>
      </main>
    </div>
  );
};
